import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import Api from 'api';
import Endpoints from 'api/endpoints';
//import Urls from 'routes/urls';
import store from 'redux/store/index';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import LanguageMenu from './LanguageMenu';
import SearchForm from 'components/SearchForm/SearchForm';

import {
	Grid,
	Hidden,
	Menu,
	MenuItem,
	AppBar as MuiAppBar,
	IconButton as MuiIconButton,
	Toolbar,
} from '@material-ui/core';

import { Menu as MenuIcon } from 'react-feather';
import Avatar from '@material-ui/core/Avatar';

import { removeUser } from 'utils/';

const AppBar = styled(MuiAppBar)`
	background: ${(props) => props.theme.header.background};
	color: ${(props) => props.theme.header.color};
	box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
	svg {
		width: 22px;
		height: 22px;
	}
`;

const AvatarSmall = styled(Avatar)`
	width: 23px;
	height: 23px;
	font-size: 0.8rem;
`;

class UserMenu extends Component {
	state = {
		anchorMenu: null,
	};

	// goToProfile = () => {
	// 	const { history } = this.props;
	// 	history.push(Urls.userProfile);
	// };

	logout = () => {
		const { history } = this.props;
		Api.logout.post().then((res) => {
			localStorage.clear();
			history.push(Endpoints.login);
			removeUser();
		});
	};

	toggleMenu = (event) => {
		this.setState({ anchorMenu: event.currentTarget });
	};

	closeMenu = () => {
		this.setState({ anchorMenu: null });
	};

	render() {
		const { anchorMenu } = this.state;
		const open = Boolean(anchorMenu);
		const { user } = store.getState().userReducer;

		const avatarLetters =
			user && user.name !== '' && user.name.split(' ').length > 1
				? `${user.name.split(' ')[0].substr(0, 1)}${user.name.split(' ')[1].substr(0, 1)}`
				: `${user && user.name !== '' ? user.name.substr(0, 2) : ''} `;

		return (
			<React.Fragment>
				<IconButton
					aria-owns={open ? 'menu-appbar' : undefined}
					aria-haspopup="true"
					onClick={this.toggleMenu}
					color="inherit"
				>
					<AvatarSmall>{avatarLetters.toUpperCase()}</AvatarSmall>
				</IconButton>
				<Menu id="menu-appbar" anchorEl={anchorMenu} open={open} onClose={this.closeMenu}>
					{/* <MenuItem
						onClick={() => {
							this.closeMenu();
							this.goToProfile();
						}}
					>
						Profile
					</MenuItem> */}
					<MenuItem
						onClick={() => {
							this.closeMenu();
							this.logout();
						}}
					>
						Sign out
					</MenuItem>
				</Menu>
			</React.Fragment>
		);
	}
}

const Header = ({ onDrawerToggle, history, t, onSearch, ...rest }) => {
	return (
		<React.Fragment>
			<AppBar position="sticky" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center" wrap="nowrap">
						<Hidden mdUp>
							<Grid item>
								<IconButton
									color="inherit"
									aria-label="Open drawer"
									onClick={onDrawerToggle}
								>
									<MenuIcon />
								</IconButton>
							</Grid>
						</Hidden>
						<Grid item>
							<SearchForm pathname={history.location.pathname} onSearch={onSearch} />
						</Grid>
						<Grid item xs />
						<Grid item>
							<Grid container alignItems="center" wrap="nowrap">
								<LanguageMenu />
								<UserMenu history={history} user={store.getState().userReducer} />
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
};

export default connect()(withRouter(withTheme(withTranslation()(Header))));

import login from './services/login';
import logout from './services/logout';
import users from './services/users';
import customerGroups from './services/customerGroups';
import devices from './services/devices';
import profiles from './services/profiles';
import applications from './services/applications';
import webapps from './services/webapps';
import hardwares from './services/hardwares';
import stats from './services/stats';
import scripts from './services/scripts';
import libraries from './services/libraries';
import softwareVersions from './services/softwareVersions';
import customers from './services/customers';
import configurations from './services/configurations';
import password from './services/password';
import cpuusage from './services/cpuusage';
import memoryusage from './services/memoryusage';
import firmwares from './services/firmwares';
import maintenances from './services/maintenances';
import uploads from './services/uploads';
import eventhubs from './services/eventhubs';
import routes from './services/routes';
import streams from './services/streams';
import links from './services/links';
import services from './services/services';
import actions from './services/actions';

class Api {
	static login = login;
	static logout = logout;
	static users = users;
	static customerGroups = customerGroups;
	static devices = devices;
	static profiles = profiles;
	static applications = applications;
	static webapps = webapps;
	static hardwares = hardwares;
	static stats = stats;
	static scripts = scripts;
	static libraries = libraries;
	static softwareVersions = softwareVersions;
	static customers = customers;
	static configurations = configurations;
	static password = password;
	static cpuusage = cpuusage;
	static memoryusage = memoryusage;
	static firmwares = firmwares;
	static maintenances = maintenances;
	static uploads = uploads;
	static eventhubs = eventhubs;
	static routes = routes;
	static streams = streams;
	static links = links;
	static services = services;
	static actions = actions;
}

export default Api;

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetHardwares' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetHardware', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(hardware) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddHardware',
				name: hardware.name,
				oui: hardware.oui,
				productClass: hardware.productClass,
				version: hardware.version,
				manufacturer: hardware.manufacturer,
				defaultProfile: hardware.defaultProfile,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteHardware',
				id: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(hardware) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateHardware',
				id: hardware.id,
				name: hardware.name,
				oui: hardware.oui,
				productClass: hardware.productClass,
				version: hardware.version,
				manufacturer: hardware.manufacturer,
				defaultProfile: hardware.defaultProfile,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default { getAll, getById, add, del, update };

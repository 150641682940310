const Schema = {
	device: {
		id: 'id',
		serialNumber: 'SerialNumber',
		hardware: 'Hardware',
		hwVersion: ['HardwareName', 'HardwareVersion'],
		profile: 'ProfileName',
		swVersion: 'SoftwareVersion',
		configVersion: 'ConfigFileVersion',
		publicIp: 'UDPConnectionRequestAddress',
		status: 'Status',
		ingestion: 'IngestionName',
		lastInform: 'LastInform',
		lastActivity: 'LastActivity',
		ingestionId: 'IngestionId',
		actions: 'Actions',
		latitude: 'Latitude',
		longitude: 'Longitude',
	},
	profile: {
		id: 'id',
		name: 'Name',
		registeredDevices: 'registeredDevices',
		registeredDevicesNames: 'registeredDevicesNames',
		scheduling: 'StartTime',
		script: 'Script',
		startTime: 'StartTime',
		upgradeRate: 'UpgradeRate',
		description: 'Description',
		creationDate: 'CreationTime',
	},
	profileApplication: {
		profile: 'Profile',
		application: 'Application',
	},
	profileWebApp: {
		profile: 'Profile',
		webApp: 'WebApp',
	},
	profileFirmware: {
		profile: 'Profile',
		firmware: 'Firmware',
	},
	profileConfiguration: {
		profile: 'Profile',
		configuration: 'Configuration',
	},
	applications: {
		id: 'id',
		name: 'Name',
		bundles: 'Bundles',
		version: 'Version',
		creationDate: 'CreationTime',
		libraries: 'Libraries',
	},
	applicationsLibraries: {
		application: 'Application',
		library: 'Library',
	},
	webapps: {
		id: 'id',
		name: 'Name',
		contextPath: 'ContextPath',
		creationDate: 'CreationTime',
		fileName: 'FileName',
	},
	scripts: {
		id: 'id',
		name: 'Name',
		type: 'Type',
		description: 'Description',
		code: 'Code',
		adminOnly: 'AdminOnly',
	},
	scriptParameters: {
		id: 'id',
		name: 'Name',
		description: 'Description',
		script: 'Script',
	},
	stats: {
		service: 'Service',
		sumReceived: 'SUM(Received)',
		sumSent: 'SUM(Sent)',
		sumConnections: 'SUM(Connections)',
		maxMaxRxRate: 'MAX(MaxRxRate)',
		maxMaxTxRate: 'MAX(MaxTxRate)',
		maxMaxConnRate: 'MAX(MaxConnRate)',
	},
	libraries: {
		id: 'id',
		name: 'Name',
		vendor: 'Vendor',
		uuid: 'UUID',
		version: 'Version',
		description: 'Description',
		runLevel: 'RunLevel',
		fileName: 'FileName',
		iconMimeType: 'IconMimeType',
		icon: 'Icon',
	},
	softwareVersions: {
		id: ['SoftwareVersion', 'HardwareName', 'HardwareVersion'],
		swVersion: 'SoftwareVersion',
		hwName: ['HardwareName', 'HardwareVersion'],
		creationDate: 'CreationDate',
	},
	customers: {
		id: 'id',
		username: 'UserName',
		name: 'Name',
		company: 'Company',
		address: 'Address',
		email: 'Email',

		extra: 'Extra',
		customergroups: 'ConsumerGroup',
		customerdevices: 'SerialNumber',
	},
	customerGroups: {
		id: 'id',
		name: 'Name',
		description: 'Description',
		maxSize: 'MaxSize',
		size: 'Size',
		overSize: 'overSize',
		customerNames: 'CustomerNames',
		eventHubNames: 'EventHubNames',
	},
	customerGroups_eventhub: {
		id: 'id',
		eventHub: 'EventHub',
		customerGroup: 'ConsumerGroup',
	},
	customerGroups_output: {
		id: 'id',
		name: 'Name',
		maxNumberOfRows: 'MaxNumberOfRows',
		indexes: 'indexes',
		type: 'Type',
		format: 'Format',
		arrayName: 'ArrayName',
		ignoreNullFields: 'IgnoreNullFields',
		customerGroup: 'ConsumerGroup',
	},
	customerGroups_output_fields: {
		id: 'id',
		output: 'Output',
		fields: 'Fields',
		isUnique: 'IsUnique',
	},
	users: {
		id: 'id',
		username: 'UserName',
		name: 'Name',
		role: 'Role',
		email: 'Email',
		permissions: 'Permissions',
		lastActivity: 'LastActivity',
	},
	configurations: {
		id: 'id',
		fileName: 'FileName',
		version: 'Version',
		description: 'Description',
		hardware: 'Hardware',
		hardwareName: ['HardwareName', 'HardwareVersion'],
		creationDate: 'CreationTime',
	},
	hardwares: {
		id: 'id',
		name: 'Name',
		version: 'HardwareVersion',
		manufacturer: 'Manufacturer',
		oui: 'OUI',
		productClass: 'ProductClass',
		defaultProfile: 'DefaultProfile',
		defaultProfileName: 'DefaultProfileName',
		dependences: 'Dependences',
	},
	cpuusage: {
		id: 'id',
		server: 'Server',
		timeStamp: 'Timestamp',
		interval: 'Interval',
		usage: 'Usage',
	},
	memoryusage: {
		id: 'id',
		server: 'Server',
		timeStamp: 'Timestamp',
		interval: 'Interval',
		maxUsage: 'MaxUsage',
		capacity: 'Capacity',
	},
	firmwares: {
		id: 'id',
		version: 'Version',
		hardware: 'Hardware',
		hardwareName: ['HardwareName', 'HardwareVersion'],
		fileName: 'FileName',
		description: 'Description',
		creationDate: 'CreationTime',
	},
	maintenances: {
		id: 'id',
		subject: 'Subject',
		message: 'Message',
		flags: 'Flags',
		expiration: 'Expiration',
	},
	ingestion: {
		id: 'id',
		name: 'Name',
		description: 'Description',
		maxSize: 'MaxSize',
		persistence: 'Persistence',
		devices: 'devices',
	},
	ingestion_input: {
		id: 'id',
		name: 'Name',
		hidden: 'Hidden',
		enable: 'Enable',
		maxNumberOfRows: 'MaxNumberOfRows',
		lateArrivalTolerance: 'LateArrivalTolerance',
		fields: 'Fields',
		timestamp: 'Timestamp',
		parent: 'Parent',
		ingestion: 'EventHub',
		events: 'Events',
		rowsXPath: 'RowsXPath',
		filter: 'Filter',
	},
	ingestion_input_field: {
		id: 'id',
		input: 'Input',
		name: 'Name',
		xPath: 'XPath',
		hidden: 'Hidden',
	},
	device_customer: {
		id: 'id',
		customer: 'Customer',
		device: 'Device',
		role: 'Role',
		permissions: 'Permissions',
	},
	customer_customerGroup: {
		id: 'id',
		customer: 'Customer',
		customerGroup: 'ConsumerGroup',
		description: 'Description',
		role: 'Role',
		permissions: 'Permissions',
	},
	routes: {
		id: 'id',
		name: 'Name',
		eventHubName: 'EventHubName',
		inputName: 'InputName',
		arrow: 'Arrow',
		customerGroupName: 'ConsumergroupName',
		outputName: 'OutputName',
		customerGroup: 'ConsumerGroup',
		output: 'Output',
		input: 'Input',
		enable: 'Enable',
		creationDate: 'CreationTime',
	},
	streams: {
		id: 'id',
		name: 'Name',
		customerGroup: 'ConsumerGroup',
		customerGroupName: 'ConsumergroupName',
		output: 'Output',
		outputName: 'OutputName',
		timestampBy: 'TimestampBy',
		enable: 'Enable',
		windowType: 'WindowType',
		windowSize: 'WindowSize',
		hopSize: 'HopSize',
		timeoutSize: 'TimeoutSize',
		maxDurationSize: 'MaxDurationSize',
		query: 'Query',
		creationDate: 'CreationTime',
	},
	links: {
		id: 'id',
		eventHub: 'EventHub',
		eventHubName: 'EventHubName',
		eventHubDescription: 'EventHubDescription',
		readOnly: 'ReadOnly',
		customerGroupName: 'ConsumerGroupName',
		customerGroupDescription: 'ConsumerGroupDescription',
		creationDate: 'CreationTime',
		customerGroup: 'ConsumerGroup',
	},
	stream_trigger: {
		id: 'id',
		stream: 'Stream',
		input: 'Input',
	},
	services: {
		dBVersion: 'DBVersion',
		serverPort: 'ServerPort',
		acsUsername: 'AcsUserName',
		acsPassword: 'AcsPassword',
		fsUsername: 'FsUserName',
		fsPassword: 'FsPassword',
		email: 'Email',
		smtpServer: 'SmtpServer',
		smtpPort: 'SmtpPort',
		smtpUsername: 'SmtpUserName',
		smtpPassword: 'SmtpPassword',
		jabberID: 'JabberID',
		xmppServer: 'XmppServer',
		xmppPort: 'XmppPort',
		xmppPassword: 'XmppPassword',
	},
	actions: {
		id: 'id',
		device: 'Device',
		script: 'Script',
		scriptName: 'ScriptName',
		collapseId: 'CollapseId',
		parameter: 'Parameter',
	},
};

export default Schema;

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const cookies = document.cookie;
let chosenLang = 'en';
if (cookies) {
	const langCookie = cookies.split('; ').find((row) => row.startsWith('chosenLanguage'));
	const langCookieOk = langCookie ? langCookie.split('=') : [];
	if (langCookieOk.length > 0) chosenLang = langCookieOk[1];
}

i18n.use(Backend)
	.use(initReactI18next)
	.init({
		lng: chosenLang,
		backend: {
			/* translation file path */
			loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
		},
		fallbackLng: 'en',
		debug: false,
		/* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
		ns: ['translations'],
		defaultNS: 'translations',
		keySeparator: false,
		interpolation: {
			escapeValue: false,
			formatSeparator: ',',
		},
		react: {
			wait: true,
			useSuspense: false,
		},
	});

export default i18n;

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetStreams' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetStream', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getByOutputId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetStreamsByOutput', output: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(stream) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddStream',
				customerGroup: stream.customerGroup,
				output: stream.output,
				timestampBy: stream.timestampBy,
				enable: stream.enable,
				name: stream.name,
				windowType: stream.windowType,
				windowSize: stream.windowSize,
				hopSize: stream.hopSize,
				timeoutSize: stream.timeoutSize,
				maxDurationSize: stream.maxDuration,
				query: stream.query,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addTrigger(trigger) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddStreamTrigger',
				stream: trigger.stream,
				input: trigger.input,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteStream', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delTriggers(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteStreamTriggers', stream: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getTriggers(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetStreamTriggers', stream: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delTriggerById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteStreamTriggersById', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(stream) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateStream',
				id: stream.id,
				customerGroup: stream.customerGroup,
				output: stream.output,
				timestampBy: stream.timestampBy,
				enable: stream.enable,
				name: stream.name,
				windowType: stream.windowType,
				windowSize: stream.windowSize,
				hopSize: stream.hopSize,
				timeoutSize: stream.timeoutSize,
				maxDuration: stream.maxDuration,
				query: stream.query,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default {
	getAll,
	getById,
	getByOutputId,
	add,
	addTrigger,
	del,
	delTriggers,
	getTriggers,
	delTriggerById,
	update,
};

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll(isAdmin, username) {
	return HttpClient.options({})
		.url(isAdmin ? `${EndPoints.mngJson}` : `${EndPoints.capiJson}`)
		.body(
			JSON.stringify(
				isAdmin
					? { Command: 'GetUsers' }
					: { Command: 'GetUserByUsername', username: username }
			)
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getAllCapi(isAdmin, username) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify(
				isAdmin
					? { Command: 'GetUsers' }
					: { Command: 'GetUserByUsername', username: username }
			)
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetUser', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getByUsername(username) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetUserByUsername', username: username }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(user) {
	const body = {
		Command: 'AddUser',
		...user,
	};
	return HttpClient.options({})
		.url(`${EndPoints.mngJson}`)
		.body(JSON.stringify(body))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(user) {
	const body = {
		Command: 'UpdateUser',
		id: user.id,
		name: user.name,
		email: user.email,
		permissions: user.permissions,
	};

	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify(body))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function edit(userName, fields) {
	const body = {
		Command: 'ModifyUser',
		UserName: userName,
		...fields,
	};
	return HttpClient.options({})
		.url(`${EndPoints.mngJson}`)
		.body(JSON.stringify(body))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function remove(userName) {
	const body = {
		Command: 'RemoveUser',
		UserName: userName,
	};
	return HttpClient.options({})
		.url(`${EndPoints.mngJson}`)
		.body(JSON.stringify(body))
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default { getAll, getAllCapi, getById, getByUsername, add, edit, remove, update };

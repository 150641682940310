const devUrl = 'https://admin.sparkjvl.com:8000';
const isDev = window.location.href.indexOf('localhost:8001') !== -1;

const EndPoints = {
	BASE_URL: isDev ? devUrl : '',
	login: '/login',
	mngJson: '/mng/json',
	mngEvents: '/mng/event',
	mngUpload: '/mng/upload',
	uploadProgress: '/upload_progress',
	capiJson: '/capi/json',
};

export default EndPoints;

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetEventHubs' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetEventHub', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(ingestion) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddEventHub',
				name: ingestion.name,
				description: ingestion.description,
				maxSize: ingestion.maxSize,
				persistence: ingestion.persistence,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteEventHub', eventHub: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(ingestion) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateEventHub',
				id: ingestion.id,
				name: ingestion.name,
				description: ingestion.description,
				maxSize: ingestion.maxSize,
				persistence: ingestion.persistence,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getInputsByEventHubId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'GetInputsByEventHubId',
				id: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getInputsFieldsByInputId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'GetInputsFieldsByInputId',
				id: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delInputByEventHubId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteInputByEventHubId',
				id: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delInputById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteInputById',
				id: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delInputFieldsByInputId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteInputFieldsByInputId',
				id: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addInput(input) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddInput',
				name: input.name,
				enable: input.enable,
				hidden: input.hidden,
				maxNumberOfRows: input.maxNumberOfRows,
				lateArrivalTolerance: input.lateArrivalTolerance,
				timestamp: input.timestamp,
				events: input.events,
				rowsXPath: input.rowsXPath,
				filter: input.filter,
				parent: input.parent === 0 || input.parent === '' ? null : input.parent,
				ingestion: input.ingestion,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addInputField(field) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddInputField',
				input: field.input,
				name: field.name,
				xPath: field.xPath,
				hidden: field.hidden,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function updateInput(input) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateInput',
				id: input.id,
				enable: input.enable,
				hidden: input.hidden,
				maxNumberOfRows: input.maxNumberOfRows,
				lateArrivalTolerance: input.lateArrivalTolerance,
				timestamp: input.timestamp,
				filter: input.filter,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getAllInputs() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetEventHubsInputs' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getInputById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetInput', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default {
	getAll,
	getById,
	add,
	del,
	update,
	getInputsByEventHubId,
	delInputById,
	delInputByEventHubId,
	delInputFieldsByInputId,
	addInput,
	addInputField,
	updateInput,
	getInputsFieldsByInputId,
	getAllInputs,
	getInputById,
};

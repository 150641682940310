import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetLinks' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetLink', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteLink', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(link) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddLink',
				customerGroup: link.customerGroup,
				eventHub: link.eventhub,
				readOnly: link.readOnly,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default {
	getAll,
	getById,
	del,
	add,
};

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetRoutes' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetRoute', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getByOutputId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetRoutesByOutput', output: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteRoute', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(link) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddRoute',
				name: link.name,
				customerGroup: link.customerGroup,
				output: link.output,
				input: link.input,
				enable: link.enable,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(route) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateRoute',
				id: route.id,
				enable: route.enable,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default {
	getAll,
	getById,
	getByOutputId,
	del,
	add,
	update,
};

import * as types from '../constants';

export default function reducer(state = { user: null }, actions) {
	switch (actions.type) {
		case types.SET_USER:
			return {
				...state,
				user: actions.payload
			};

		default:
			return state;
	}
}

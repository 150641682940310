import React, { Component } from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

import { Menu, MenuItem, IconButton as MuiIconButton } from '@material-ui/core';

import Config from 'utils/config';

const IconButton = styled(MuiIconButton)`
	svg {
		width: 22px;
		height: 22px;
	}
`;

const Flag = styled.img`
	border-radius: 50%;
	width: 22px;
	height: 22px;
`;

class LanguageMenu extends Component {
	state = {
		anchorMenu: null,
	};

	toggleMenu = (event) => {
		this.setState({ anchorMenu: event.currentTarget });
	};

	closeMenu = () => {
		this.setState({ anchorMenu: null });
	};

	changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		document.cookie = `chosenLanguage=${lang}`;
	};

	render() {
		const { anchorMenu } = this.state;
		const { t } = this.props;
		const open = Boolean(anchorMenu);

		return (
			<React.Fragment>
				<IconButton
					aria-owns={open ? 'menu-appbar' : undefined}
					aria-haspopup="true"
					onClick={this.toggleMenu}
					color="inherit"
				>
					<Flag src={`/static/img/flags/${i18n.language}.png`} alt="English" />
				</IconButton>
				<Menu id="menu-appbar" anchorEl={anchorMenu} open={open} onClose={this.closeMenu}>
					{Config.languages.map((l) => {
						return (
							<MenuItem
								key={l}
								onClick={() => {
									this.closeMenu();
									this.changeLanguage(l);
								}}
							>
								{t(`languages.${l}`)}
							</MenuItem>
						);
					})}
				</Menu>
			</React.Fragment>
		);
	}
}

export default withTranslation()(LanguageMenu);

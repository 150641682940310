import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { dashboard as dashboardRoutes, auth as authRoutes } from './index';

import PrivateRoute from './privateRoute';
import DashboardLayout from 'layouts/Dashboard';
import AuthLayout from 'layouts/Auth';
import Page404 from 'pages/Errors/Page404';

const childRoutes = (Layout, routes) =>
	routes.map(({ children, path, component: Component }, index) =>
		children ? (
			// Route item with children
			children.map(({ path, component: Component }, index) => (
				<Route
					key={index}
					path={path}
					exact
					render={(props) => (
						<Layout>
							<Component {...props} />
						</Layout>
					)}
				/>
			))
		) : (
			// Route item without children
			<Route
				key={index}
				path={path}
				exact
				render={(props) => {
					return (
						<Layout>
							<Component {...props} />
						</Layout>
					);
				}}
			/>
		)
	);

const childPrivateRoutes = (Layout, routes) =>
	routes.map(({ children, path, component: Component, admin }) => {
		if (children) {
			return children.map(({ path, component: Component, admin }) => (
				<PrivateRoute
					key={0}
					exact
					path={path}
					Component={Component}
					Layout={Layout}
					admin={admin}
				/>
			));
		} else {
			// Route item without children
			return (
				<PrivateRoute
					key={0}
					exact
					path={path}
					Component={Component}
					Layout={Layout}
					admin={admin}
				/>
			);
		}
	});

const Routes = () => (
	<Router>
		<Switch>
			{childRoutes(AuthLayout, authRoutes)}
			{childPrivateRoutes(DashboardLayout, dashboardRoutes)}

			<Route
				render={() => (
					<AuthLayout>
						<Page404 />
					</AuthLayout>
				)}
			/>
		</Switch>
	</Router>
);

export default Routes;

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetLibraries' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetLibrary', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default { getAll, getById };

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function reset(newPassword, oldPassword) {
	return HttpClient.options({})
		.url(`${EndPoints.mngJson}`)
		.body(
			JSON.stringify({
				Command: 'ChangePassword',
				Password: newPassword,
				OldPassword: oldPassword,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default { reset };

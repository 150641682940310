const Config = {
	roles: ['Admin', 'User'],
	languages: ['en', 'it'],
	searches: {
		'/devices/all': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'serialNumber',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'hardwareVersion',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'profile',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'softwareVersion',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'publicIp',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'status',
					tagType: 'select',
					options: ['online', 'offline'],
				},
				{
					name: 'ingestion',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/devices/ingestion': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'name',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'description',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'maxSize',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'persistence',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/devices/classes': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'name',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'version',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'manufacturer',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'oui',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'defaultProfileName',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/profile/all': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'name',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'registeredDevices',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'creationDate',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/profile/applications': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'name',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'bundles',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'version',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'creationDate',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/profile/webapp': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'name',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'contextPath',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'creationDate',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/profile/swversion': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'softwareVersion',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'hardwareVersion',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'creationDate',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/profile/configurationfiles': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'version',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'hardware',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'creationDate',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/profile/scripts': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'name',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'type',
					tagType: 'select',
					options: [
						'0',
						'1',
						'2',
						'3',
						'4',
						'5',
						'6',
						'7',
						'8',
						'9',
						'10',
						'11',
						'12',
						'13',
						'14',
						'15',
						'16',
						'32',
					],
				},
				{
					name: 'description',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'adminOnly',
					tagType: 'select',
					options: ['ALL', 'Admin'],
				},
			],
		},
		'/customers/all': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'username',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'name',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'email',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'customerGroups',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'devicesSerialNumber',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/customers/groups': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'name',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'description',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'maxSizeB',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'currentSize',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'admins',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'ingestion',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/dataflow/links': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'ingestionName',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'ingestionDescription',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'readOnly',
					tagType: 'select',
					options: ['Read Only', 'Read Write'],
				},
				{
					name: 'cgName',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'cgDescription',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'creationDate',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/dataflow/routes': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'name',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'ingestionName',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'ingestionInputName',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'cgName',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'cgOutputName',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'creationDate',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/dataflow/streams': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'name',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'cgName',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'cgOutputName',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'enable',
					tagType: 'select',
					options: ['true', 'false'],
				},
				{
					name: 'windowType',
					tagType: 'select',
					options: ['Tumbling', 'Hopping', 'Sliding', 'Session'],
				},
				{
					name: 'creationDate',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/administration/users': {
			fields: [
				{
					name: 'username',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'name',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'role',
					tagType: 'select',
					options: ['User', 'Admin'],
				},
				{
					name: 'email',
					tagType: 'input',
					type: 'text',
				},
			],
		},
		'/administration/maintenances': {
			fields: [
				{
					name: 'id',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'subject',
					tagType: 'input',
					type: 'text',
				},
				{
					name: 'expiration',
					tagType: 'input',
					type: 'text',
				},
			],
		},
	},
};

export default Config;

//import Api from 'api/';
import store from 'redux/store/index';
import { setUser } from 'redux/actions/userActions';

import Schema from 'api/schemas';

const refreshInterval = () => {
	return 30000;
};

const saveUser = (user) => {
	const { dispatch } = store;
	dispatch(setUser(createDataObject(Schema.users, user)));
};

const removeUser = () => {
	const { dispatch } = store;
	dispatch(setUser(null));
};

const renderData = (d, k, e) => {
	const isNumeric =
		k === 'registeredDevices' ||
		k === 'permissions' ||
		k === 'type' ||
		k === 'usage' ||
		k === 'maxUsage' ||
		k === 'adminOnly' ||
		k === 'devices' ||
		k === 'bundles' ||
		k === 'enable' ||
		k === 'lateArrivalTolerance' ||
		k === 'maxNumberOfRows' ||
		k === 'parent' ||
		k === 'role' ||
		k === 'maxSize' ||
		k === 'size' ||
		k === 'indexes' ||
		k === 'timestampBy' ||
		k === 'windowType' ||
		k === 'windowSize' ||
		k === 'hopSize' ||
		k === 'timeoutSize' ||
		k === 'readOnly' ||
		k === 'eventHub' ||
		k === 'customerGroup' ||
		k === 'maxDurationSize' ||
		k === 'isUnique' ||
		k === 'dependences' ||
		k === 'flags' ||
		k === 'hidden' ||
		k === 'persistence';

	let defRes = isNumeric ? 0 : e ? '' : '---';
	if (isNumeric && e) defRes = '';
	if (isNumeric && e && k === 'role') defRes = d;
	return d || defRes;
};

const createDataObject = (schema, object, emptyRes) => {
	const formatted = Object.keys(schema).map((key) => {
		const objKey = schema[key];

		let objValue = null;
		if (!Array.isArray(objKey)) {
			objValue = object[objKey];
		} else {
			let objComposedValue = '';
			objKey.map((k) => {
				objComposedValue += `${object[k]} `;
				return true;
			});

			objValue = objComposedValue !== '' ? objComposedValue : null;

			if (key === 'id') objValue = sanitizeString(objValue);
		}

		if (key === 'publicIp' && objValue && objValue !== '' && objValue.indexOf(':') !== -1) {
			objValue = objValue.split(':')[0];
		}

		return [key, renderData(objValue, key, emptyRes)];
	});

	const newObject = Object.fromEntries(formatted);

	return newObject;
};

const tableDesc = (a, b, orderBy) => {
	const san = (val) => {
		let v = val.toString().toLowerCase();

		if (
			orderBy === 'registeredDevices' ||
			orderBy === 'maxSize' ||
			orderBy === 'devices' ||
			orderBy === 'bundles' ||
			orderBy === 'type' ||
			orderBy === 'size' ||
			orderBy === 'id' ||
			orderBy === 'lastInform' ||
			orderBy === 'creationDate'
		) {
			v = parseInt(v, 10);
		}

		return v;
	};

	if (orderBy !== 'swVersion') {
		if (san(b[orderBy]) < san(a[orderBy])) {
			return -1;
		}
		if (san(b[orderBy]) > san(a[orderBy])) {
			return 1;
		}
		return 0;
	} else {
		const li = a[orderBy].toString().toLowerCase().split('.');
		const ri = b[orderBy].toString().toLowerCase().split('.');
		const min = li.length > ri.length ? ri.length : li.length;

		for (var i = 0; i < min; i++) {
			li[i] = parseInt(li[i]);
			ri[i] = parseInt(ri[i]);
			if (li[i] < ri[i]) return 1;
			else if (li[i] === ri[i] && i === min - 1) return 1;
			else if (li[i] === ri[i]) continue;
			else return -1;
		}
	}
};

const tableGetSorting = (order, orderBy) =>
	order === 'desc' ? (a, b) => tableDesc(a, b, orderBy) : (a, b) => -tableDesc(a, b, orderBy);

const tableStableSort = (array, cmp) => {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
};

const formatBytes = (bytes, decimals = 2, nolabel) => {
	if (bytes === 0 || typeof bytes === 'undefined') return !nolabel ? '0 Bytes' : 0;

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	const sizeNumber =
		dm > 0
			? parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
			: parseFloat(bytes / Math.pow(k, 2));

	return !nolabel ? sizeNumber + ' ' + sizes[i] : sizeNumber;
};

const getSeviceLabel = (service) => {
	const services = ['HTTP', 'HTTPS', 'STUN', 'MQTT'];
	return services[service];
};

const composeUsername = (name) => {
	return name
		.replace(/\s/g, '_')
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');
};

const composeToSave = (schema, user) => {
	const object = {};
	Object.keys(user).map((key) => {
		const k = key === 'password' ? 'Password' : schema[key];
		object[k] = user[key];
		return true;
	});
	return object;
};

const validateField = (field) => field && field !== '';

const validateEmail = (email) => {
	var re = /\S+@\S+\.\S+/;
	return email && email !== '' && re.test(email);
};

const validatePassword = (pwd) => pwd && pwd !== '' && pwd.length >= 8;

const clearHistoryState = (history, pathname) => {
	history.replace({
		pathname: pathname,
		state: {},
	});
};

const uniqueArray = (array, prop) => {
	let resArr = [];
	array.filter(function (item) {
		let i = resArr.findIndex((x) => x[prop] === item[prop]);
		if (i <= -1) resArr.push(item);
		return null;
	});
	return resArr;
};

const sanitizeString = (string) => string.trim().replace(/\s/g, '_').toLowerCase();

const isUserAdmin = () => {
	const { user } = store.getState().userReducer;
	if (user === null) return user;
	return user && user.permissions === 0;
};

export {
	saveUser,
	removeUser,
	refreshInterval,
	createDataObject,
	tableGetSorting,
	tableStableSort,
	formatBytes,
	getSeviceLabel,
	composeUsername,
	composeToSave,
	validateField,
	validateEmail,
	validatePassword,
	clearHistoryState,
	uniqueArray,
	sanitizeString,
	isUserAdmin,
};

import { createMuiTheme } from '@material-ui/core/styles';
import { blue, green, grey } from '@material-ui/core/colors';

import typography from './typography';
import overrides from './overrides';
import breakpoints from './breakpoints';
import props from './props';
import shadows from './shadows';

const base = '#222222';
const primary = '#F57C00';
const activeMenu = '#613d17';

const variant = {
	name: 'Dark',
	palette: {
		primary: {
			main: primary,
			contrastText: base,
		},
		secondary: {
			main: primary,
			contrastText: base,
		},
		base: {
			main: base,
		},
	},
	header: {
		color: grey[500],
		background: '#FFFFFF',
		search: {
			color: grey[800],
		},
		indicator: {
			background: blue[600],
		},
	},
	sidebar: {
		color: grey[200],
		background: base,
		activeMenu: activeMenu,
		header: {
			color: grey[200],
			background: base,
			brand: {
				color: primary,
			},
		},
		footer: {
			color: grey[200],
			background: '#111111',
			online: {
				background: green[500],
			},
		},
		category: {
			fontWeight: 400,
		},
		badge: {
			color: '#FFF',
			background: blue[500],
		},
	},
	body: {
		background: '#F7F9FC',
		authBackground: base,
	},
};

const theme = (variant) => {
	return createMuiTheme(
		{
			spacing: 4,
			breakpoints: breakpoints,
			overrides: overrides,
			props: props,
			typography: typography,
			shadows: shadows,
			body: variant.body,
			header: variant.header,
			palette: variant.palette,
			sidebar: variant.sidebar,
		},
		variant.name
	);
};

export default theme(variant);

import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import Sidebar from 'components/Sidebar/Sidebar';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import { Hidden, CssBaseline, Paper as MuiPaper, withWidth } from '@material-ui/core';

import { isWidthUp } from '@material-ui/core/withWidth';

const drawerWidth = 260;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }

  .MuiIconButton-root .MuiIconButton-label svg{
	  width: 22px;
	  height: 22px;
  }
`;

const Root = styled.div`
	display: flex;
	min-height: 100vh;
`;

const Drawer = styled.div`
	${(props) => props.theme.breakpoints.up('md')} {
		width: ${drawerWidth}px;
		flex-shrink: 0;
	}
`;

const AppContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

const Paper = styled(MuiPaper)`
	padding-top: 20px;
	padding-right: 40px;
	padding-bottom: 40px;
	padding-left: 40px;
`;

const MainContent = styled(Paper)`
	flex: 1;
	background: ${(props) => props.theme.body.background};

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		flex: none;
	}

	.MuiPaper-root .MuiPaper-root {
		box-shadow: none;
	}
`;

class Dashboard extends React.Component {
	state = {
		mobileOpen: false,
		search: '',
	};

	handleDrawerToggle = () => {
		this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
	};

	render() {
		const { children, routes, width, history } = this.props;

		const childrenComponent = React.cloneElement(children, { search: this.state.search });

		return (
			<Root>
				<CssBaseline />
				<GlobalStyle />
				<Drawer>
					<Hidden mdUp implementation="js">
						<Sidebar
							routes={routes}
							PaperProps={{ style: { width: drawerWidth } }}
							variant="temporary"
							open={this.state.mobileOpen}
							onClose={this.handleDrawerToggle}
						/>
					</Hidden>
					<Hidden smDown implementation="css">
						<Sidebar routes={routes} PaperProps={{ style: { width: drawerWidth } }} />
					</Hidden>
				</Drawer>
				<AppContent>
					<Header
						onDrawerToggle={this.handleDrawerToggle}
						history={history}
						onSearch={(search) => {
							this.setState({ search });
						}}
					/>
					<MainContent p={isWidthUp('lg', width) ? 10 : 5}>
						{childrenComponent}
					</MainContent>
					<Footer />
				</AppContent>
			</Root>
		);
	}
}

export default withWidth()(Dashboard);

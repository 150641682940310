import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

async function upload(file, type, version, hw_id, name, id) {
	let url = `${EndPoints.BASE_URL}${EndPoints.mngUpload}?type=${type}`;

	if (version) url += `&version=${version}`;
	if (hw_id) url += `&hw_id=${hw_id}`;
	if (name) url += `&name=${name}`;
	if (id) url += `&id=${id}`;

	const data = new FormData();
	data.append('file', file);

	const response = await fetch(url, {
		method: 'POST',
		body: data,
	});

	return response;
}

function progress(id) {
	let url = `${EndPoints.uploadProgress}`;
	if (id) url += `?id=${id}`;

	return HttpClient.options({})
		.url(url)
		.get()
		.json((json) => json)
		.then((response) => response);
}

export default { upload, progress };

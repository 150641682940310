import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetWebApps' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetWebApp', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(webapp) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddWebApp',
				name: webapp.name,
				contextPath: webapp.contextPath,
				fileName: webapp.fileName,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteWebApp',
				id: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(webapp) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateWebApp',
				id: webapp.id,
				name: webapp.name,
				contextPath: webapp.contextPath,
				fileName: webapp.fileName,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default { getAll, getById, add, del, update };

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function post(username, password) {
	return HttpClient.options({})
		.url(`${EndPoints.login}`)
		.body(
			JSON.stringify({
				Command: 'Login',
				UserName: username,
				Password: password
			})
		)
		.post()
		.json(json => json)
		.then(response => response);
}

function getStatus() {
	return HttpClient.options({})
		.url(`${EndPoints.login}`)
		.body(JSON.stringify({ Command: 'GetStatus' }))
		.post()
		.json(json => json)
		.then(response => response);
}

export default { post, getStatus };

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetFirmwares' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetFirmware', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getAllByHardwareId(hardwareId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetFirmwaresByHardWare', hardwareId: hardwareId }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(firmware) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddFirmware',
				fileName: firmware.fileName,
				version: firmware.version,
				description: firmware.description,
				hardware: firmware.hardware,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteFirmware',
				id: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(firmware) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateFirmware',
				id: firmware.id,
				fileName: firmware.fileName,
				version: firmware.version,
				description: firmware.description,
				hardware: firmware.hardware,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default { getAll, getById, getAllByHardwareId, add, del, update };

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetSoftwareVersions' }))
		.post()
		.json(json => json)
		.then(response => response);
}

export default { getAll };

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetProfiles' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetProfile', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(profile) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddProfile',
				name: profile.name,
				description: profile.description,
				script: profile.script,
				startTime: profile.startTime,
				upgradeRate: profile.upgradeRate,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteProfile',
				id: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(profile) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateProfile',
				id: profile.id,
				name: profile.name,
				description: profile.description,
				script: profile.script,
				startTime: profile.startTime,
				upgradeRate: profile.upgradeRate,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addProfileApplication(profileId, appId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddProfileApplication',
				profile: profileId,
				application: appId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delProfileApplication(profileId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteProfileApplication',
				profile: profileId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delProfileApplicationByApplication(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteProfileApplicationByApplication',
				application: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getProfileApplications(profileId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'GetProfileApplications',
				profile: profileId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addProfileWebApp(profileId, webAppId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddProfileWebApp',
				profile: profileId,
				webApp: webAppId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getProfileWebApps(profileId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'GetProfileWebApps',
				profile: profileId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delProfileWebApp(profileId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteProfileWebApp',
				profile: profileId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delProfileWebAppByWebApp(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteProfileWebAppByWebApp',
				webapp: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addProfileFirmware(profileId, firmwareId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddProfileFirmware',
				profile: profileId,
				firmware: firmwareId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getProfileFirmwares(profileId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'GetProfileFirmwares',
				profile: profileId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delProfileFirmware(profileId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteProfileFirmware',
				profile: profileId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delProfileFirmwareByFirmware(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteProfileFirmwareByFirmware',
				firmware: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addProfileConfiguration(profileId, configurationId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddProfileConfiguration',
				profile: profileId,
				configuration: configurationId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getProfileConfigurations(profileId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'GetProfileConfigurations',
				profile: profileId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delProfileConfiguration(profileId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteProfileConfiguration',
				profile: profileId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delProfileConfigurationByConfiguration(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteProfileConfigurationByConfiguration',
				configuration: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default {
	getAll,
	getById,
	add,
	update,
	del,
	addProfileApplication,
	addProfileWebApp,
	addProfileFirmware,
	addProfileConfiguration,
	delProfileApplication,
	delProfileWebApp,
	delProfileFirmware,
	delProfileConfiguration,
	getProfileApplications,
	getProfileWebApps,
	getProfileFirmwares,
	getProfileConfigurations,
	delProfileApplicationByApplication,
	delProfileWebAppByWebApp,
	delProfileFirmwareByFirmware,
	delProfileConfigurationByConfiguration,
};

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetCustomerGroups' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getByCustomerId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetConsumerGroupByCustomer', Customer: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetCustomerGroup', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(cg) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddCustomerGroup',
				name: cg.name,
				description: cg.description,
				maxSize: cg.maxSize,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteCustomerGroup', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(cg) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateCustomerGroup',
				id: cg.id,
				name: cg.name,
				description: cg.description,
				maxSize: cg.maxSize,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delCustomerCustomerGroup(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteCustomerCustomerGroupByCustomerGroupId', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delLinksByCustomerGroupId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteCustomerGroupLinks', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getLinksByCustomerGroupId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetCustomerGroupLinks', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getCustomersByCustomerGroupId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetCustomerGroupCustomers', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getCustomerGroupOutputs(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetCustomerGroupOutputs', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addOutput(o) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddOutput',
				name: o.name,
				maxNumberOfRows: o.maxNumberOfRows,
				customerGroup: o.customerGroup,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getOutputFields(output) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'GetOutputFields',
				output: output,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addOutputField(o) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddOutputField',
				output: o.output,
				fields: o.name,
				unique: o.unique,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delOutputById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteOutput', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delOutputFieldsByOutputId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteOutputFieldByOutputId', output: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getAllOutputs() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetAllCustomerGroupOutputs' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default {
	getAll,
	getByCustomerId,
	getById,
	add,
	del,
	update,
	delLinksByCustomerGroupId,
	getLinksByCustomerGroupId,
	getCustomersByCustomerGroupId,
	getCustomerGroupOutputs,
	addOutput,
	addOutputField,
	delOutputById,
	delOutputFieldsByOutputId,
	getAllOutputs,
	delCustomerCustomerGroup,
	getOutputFields,
};

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll(isAdmin) {
	const command = isAdmin ? { Command: 'GetScripts' } : { Command: 'GetScriptsUser' };
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify(command))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetScript', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function run(script, devices, parameters) {
	const obj = {
		Action: 'ExecuteScript',
		Script: script.id,
		Devices: devices,
	};
	if (parameters) obj.Parameters = parameters;

	return HttpClient.options({})
		.headers({
			'content-type': 'application/json; charset=UTF-8',
		})
		.url(`${EndPoints.mngJson}`)
		.body(JSON.stringify(obj))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getScriptParameters(scriptId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetScriptParameters', script: scriptId }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(script) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddScript',
				name: script.name,
				type: script.type,
				code: script.code,
				description: script.description,
				adminOnly: script.adminOnly ? 1 : 0,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteScript', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addParameter(param) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddScriptParameter',
				name: param.name,
				script: param.script,
				description: param.description,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delParam(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteScriptParameter', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delParamsByScriptId(scriptId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteScriptParameterByScriptId', scriptId: scriptId }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(script) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateScript',
				id: script.id,
				name: script.name,
				description: script.description,
				code: script.code,
				adminOnly: script.adminOnly,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function updateParameter(script) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateScriptParameter',
				id: script.id,
				name: script.name,
				description: script.description,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default {
	getAll,
	getById,
	run,
	getScriptParameters,
	add,
	del,
	addParameter,
	delParamsByScriptId,
	update,
	updateParameter,
	delParam,
};

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetCustomers' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetCustomer', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(customer) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddCustomer',
				name: customer.name,
				username: customer.username,
				email: customer.email,
				address: customer.address,
				company: customer.company,
				password: customer.password,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(customer) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateCustomer',
				id: customer.id,
				name: customer.name,
				email: customer.email,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteCustomer', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addDeviceCustomer(deviceCustomer) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddDeviceCustomer',
				customer: deviceCustomer.customer,
				device: deviceCustomer.device,
				role: deviceCustomer.role,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addCustomerCustomerGroup(cgCustomer) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddCustomerCustomerGroup',
				customer: cgCustomer.customer,
				customerGroup: cgCustomer.customerGroup,
				role: cgCustomer.role,
				description: cgCustomer.description,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function updateCustomerCustomerGroup(cgCustomer) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateCustomerCustomerGroup',
				id: cgCustomer.id,
				customer: cgCustomer.customer,
				customerGroup: cgCustomer.customerGroup,
				role: cgCustomer.role,
				description: cgCustomer.description,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function updateCustomerDevice(dCustomer) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateCustomerDevice',
				id: dCustomer.id,
				customer: dCustomer.customer,
				device: dCustomer.device,
				role: dCustomer.role,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function updateCustomerDeviceByCustomer(dCustomer) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateCustomerDeviceByCustomer',
				customer: dCustomer.customer,
				device: dCustomer.device,
				role: dCustomer.role,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getCustomerDevicesByCustomerId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetCustomerDevicesByCustomerId', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getCustomerCustomerGroupsByCustomerId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetCustomerCustomerGroupsByCustomerId', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delCustomerCustomerGroup(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteCustomerCustomerGroup', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delCustomerDevice(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteCustomerDevice', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default {
	getAll,
	getById,
	update,
	del,
	add,
	addDeviceCustomer,
	addCustomerCustomerGroup,
	getCustomerDevicesByCustomerId,
	getCustomerCustomerGroupsByCustomerId,
	delCustomerCustomerGroup,
	delCustomerDevice,
	updateCustomerCustomerGroup,
	updateCustomerDevice,
	updateCustomerDeviceByCustomer,
};

import React from 'react';

import Urls from 'routes/urls';
import async from '../components/Async/Async';

import { Sliders, Users as UsersIcon, Grid, Radio, List, Link } from 'react-feather';

const Login = async(() => import('../pages/Auth/Login'));
const SignUp = async(() => import('../pages/Auth/SignUp'));
const ResetPassword = async(() => import('../pages/Auth/ResetPassword'));
const Page404 = async(() => import('../pages/Errors/Page404'));
const Page500 = async(() => import('../pages/Errors/Page500'));

const Dashboard = async(() => import('../pages/Dashboard/Dashboard'));
const Privacy = async(() => import('../pages/Static/Privacy'));
const Terms = async(() => import('../pages/Static/Terms'));

const UserProfile = async(() => import('../pages/UserProfile/UserProfile'));

const Devices = async(() => import('../pages/Devices/Devices'));
const Device = async(() => import('../pages/Devices/Device'));

const DevicesIngestion = async(() => import('../pages/Devices/DevicesIngestion'));
const DeviceIngestionAdd = async(() => import('../pages/Devices/DeviceIngestionAdd'));
const DeviceIngestion = async(() => import('../pages/Devices/DeviceIngestion'));

const Classes = async(() => import('../pages/Classes/Classes'));
const ClassAdd = async(() => import('../pages/Classes/ClassAdd'));
const Class = async(() => import('../pages/Classes/Class'));

const Profiles = async(() => import('../pages/Profiles/Profiles'));
const Profile = async(() => import('../pages/Profiles/Profile'));
const ProfileAdd = async(() => import('../pages/Profiles/ProfileAdd'));

const Applications = async(() => import('../pages/Applications/Applications'));
const ApplicationAdd = async(() => import('../pages/Applications/ApplicationAdd'));
const Application = async(() => import('../pages/Applications/Application'));

const ConfigurationFiles = async(() => import('../pages/ConfigurationFiles/ConfigurationFiles'));
const ConfigurationFileAdd = async(() =>
	import('../pages/ConfigurationFiles/ConfigurationFileAdd')
);
const ConfigurationFile = async(() => import('../pages/ConfigurationFiles/ConfigurationFile'));

const Scripts = async(() => import('../pages/Scripts/Scripts'));
const ScriptsAdd = async(() => import('../pages/Scripts/ScriptAdd'));
const Script = async(() => import('../pages/Scripts/Script'));

const WebApps = async(() => import('../pages/WebApps/WebApps'));
const WebApp = async(() => import('../pages/WebApps/WebApp'));
const WebAppAdd = async(() => import('../pages/WebApps/WebAppAdd'));

const SoftwareVersions = async(() => import('../pages/SoftwareVersions/SoftwareVersions'));
const SoftwareVersion = async(() => import('../pages/SoftwareVersions/SoftwareVersion'));
const SoftwareVersionAdd = async(() => import('../pages/SoftwareVersions/SoftwareVersionAdd'));

const Customers = async(() => import('../pages/Customers/Customers'));
const CustomerAdd = async(() => import('../pages/Customers/CustomerAdd'));
const Customer = async(() => import('../pages/Customers/Customer'));

const CustomerGroups = async(() => import('../pages/Customers/CustomerGroups'));
const CustomerGroupAdd = async(() => import('../pages/Customers/CustomerGroupAdd'));
const CustomerGroup = async(() => import('../pages/Customers/CustomerGroup.js'));

const Users = async(() => import('../pages/Users/Users'));
const User = async(() => import('../pages/Users/User'));
const UserAdd = async(() => import('../pages/Users/UserAdd'));

const Maintenances = async(() => import('../pages/Maintenance/Maintenances'));
const Maintenance = async(() => import('../pages/Maintenance/Maintenance'));
const MaintenanceAdd = async(() => import('../pages/Maintenance/MaintenanceAdd'));

const Links = async(() => import('../pages/DataFlow/Links'));
const LinkAdd = async(() => import('../pages/DataFlow/LinkAdd'));

const RoutesData = async(() => import('../pages/DataFlow/RoutesData'));
const RouteDataAdd = async(() => import('../pages/DataFlow/RouteDataAdd'));
const RouteData = async(() => import('../pages/DataFlow/RouteData'));

const Streams = async(() => import('../pages/DataFlow/Streams'));
const Stream = async(() => import('../pages/DataFlow/Stream'));
const StreamAdd = async(() => import('../pages/DataFlow/StreamAdd'));

const Services = async(() => import('../pages/Services/Services'));
const Console = async(() => import('../pages/Console/Console'));

const dashboardsRoutes = {
	id: 'dashboard',
	path: Urls.dashboard,
	icon: <Grid />,
	containsHome: true,
	component: Dashboard,
};

const userProfileRoutes = {
	id: 'user',
	path: Urls.userProfile,
	component: UserProfile,
	admin: true,
};

const devicesRoutes = {
	id: 'devices',
	path: Urls.devices,
	icon: <Radio />,
	component: Devices,
	children: [
		{
			path: Urls.devicesAll,
			name: 'devicesAll',
			component: Devices,
		},
		{
			path: Urls.devicesIngestion,
			name: 'devicesIngestion',
			component: DevicesIngestion,
			admin: true,
		},
		{
			path: Urls.devicesClasses,
			name: 'devicesClasses',
			component: Classes,
			admin: true,
		},
	],
};

const deviceDetailRoute = {
	id: 'device',
	path: Urls.devicesSingle,
	component: Device,
};

const deviceIngestionAddRoute = {
	id: 'devicesIngestionAdd',
	path: Urls.devicesIngestionAdd,
	component: DeviceIngestionAdd,
	admin: true,
};

const deviceIngestionDetailRoute = {
	id: 'devicesIngestionSingle',
	path: Urls.devicesIngestionSingle,
	component: DeviceIngestion,
	admin: true,
};

const deviceClassesAddRoute = {
	id: 'classAdd',
	path: Urls.devicesClassAdd,
	component: ClassAdd,
	admin: true,
};

const deviceClassesDetailRoute = {
	id: 'classSingle',
	path: Urls.devicesClassSingle,
	component: Class,
	admin: true,
};

const profileRoutes = {
	id: 'profile',
	path: Urls.profile,
	icon: <List />,
	admin: true,
	children: [
		{
			path: Urls.profileAll,
			name: 'profileAll',
			component: Profiles,
			admin: true,
		},
		{
			path: Urls.profileApplications,
			name: 'profileApplications',
			component: Applications,
			admin: true,
		},
		{
			path: Urls.profileWebApp,
			name: 'profileWebApp',
			component: WebApps,
			admin: true,
		},
		{
			path: Urls.profileSwVersion,
			name: 'profileSwVersion',
			component: SoftwareVersions,
			admin: true,
		},
		{
			path: Urls.profileConfigurationFiles,
			name: 'profileConfigurationFiles',
			component: ConfigurationFiles,
			admin: true,
		},
		{
			path: Urls.profileScripts,
			name: 'profileScripts',
			component: Scripts,
			admin: true,
		},
	],
};

const profileAddRoute = {
	id: 'profileAdd',
	path: Urls.profileAdd,
	component: ProfileAdd,
	admin: true,
};

const profileApplicationAddRoute = {
	id: 'profileApplicationAdd',
	path: Urls.profileApplicationAdd,
	component: ApplicationAdd,
	admin: true,
};

const profileScriptsAddRoute = {
	id: 'profileScriptsAdd',
	path: Urls.profileScriptsAdd,
	component: ScriptsAdd,
	admin: true,
};

const profileDetailRoute = {
	id: 'profileDetail',
	path: Urls.profileSingle,
	component: Profile,
	admin: true,
};

const profileApplicationDetailRoute = {
	id: 'applicationDetail',
	path: Urls.profileApplicationSingle,
	component: Application,
	admin: true,
};

const profileScriptDetailRoute = {
	id: 'scriptsDetail',
	path: Urls.profileScriptsSingle,
	component: Script,
	admin: true,
};

const profileWebAppAddRoute = {
	id: 'webappAdd',
	path: Urls.profileWebAppAdd,
	component: WebAppAdd,
	admin: true,
};

const profileWebAppDetailRoute = {
	id: 'webappDetail',
	path: Urls.profileWebAppSingle,
	component: WebApp,
	admin: true,
};

const profileSwVersionAddRoute = {
	id: 'softwareVersionAdd',
	path: Urls.profileSwVersionAdd,
	component: SoftwareVersionAdd,
	admin: true,
};

const profileSwVersionDetailRoute = {
	id: 'softwareVersionDetail',
	path: Urls.profileSwVersionSingle,
	component: SoftwareVersion,
	admin: true,
};

const profileConfigurationFileAddRoute = {
	id: 'configurationFileAdd',
	path: Urls.profileConfigurationFilesAdd,
	component: ConfigurationFileAdd,
	admin: true,
};

const profileConfigurationFileDetailRoute = {
	id: 'configurationFileDetail',
	path: Urls.profileConfigurationFilesSingle,
	component: ConfigurationFile,
	admin: true,
};

const maintenanceAddRoute = {
	id: 'administrationMaintenanceAdd',
	path: Urls.administrationMaintenanceAdd,
	component: MaintenanceAdd,
	admin: true,
};

const maintenanceDetailRoute = {
	id: 'administrationMaintenanceSingle',
	path: Urls.administrationMaintenanceSingle,
	component: Maintenance,
	admin: true,
};

const customerRoutes = {
	id: 'customers',
	path: Urls.customers,
	icon: <UsersIcon />,
	children: [
		{
			path: Urls.customersAll,
			name: 'customersAll',
			component: Customers,
		},
		{
			path: Urls.customerGroups,
			name: 'customerGroups',
			component: CustomerGroups,
			admin: true,
		},
	],
};

const customerAddRoute = {
	id: 'customerAdd',
	path: Urls.customerAdd,
	component: CustomerAdd,
	admin: true,
};

const customerDetailRoute = {
	id: 'customerDetail',
	path: Urls.customerSingle,
	component: Customer,
};

const customerGroupAddRoute = {
	id: 'customerGroupAdd',
	path: Urls.customerGroupAdd,
	component: CustomerGroupAdd,
	admin: true,
};

const customerGroupDetailRoute = {
	id: 'customerGroupDetail',
	path: Urls.customerGroupSingle,
	component: CustomerGroup,
	admin: true,
};

const adminRoutes = {
	id: 'administration',
	path: Urls.administration,
	icon: <Sliders />,
	children: [
		{
			path: Urls.administrationAll,
			name: 'administrationAll',
			component: Users,
		},
		{
			path: Urls.administrationServices,
			name: 'administrationServices',
			component: Services,
			admin: true,
		},
		{
			path: Urls.administrationConsole,
			name: 'administrationConsole',
			component: Console,
			admin: true,
		},
		{
			path: Urls.administrationMaintenance,
			name: 'administrationMaintenance',
			component: Maintenances,
			admin: true,
		},
	],
};

const userAddRoute = {
	id: 'administrationUsersAdd',
	path: Urls.administrationUsersAdd,
	component: UserAdd,
	admin: true,
};

const userDetailRoute = {
	id: 'administrationUsersSingle',
	path: Urls.administrationUsersSingle,
	component: User,
};

const privacyRoutes = {
	id: 'privacy',
	path: Urls.privacy,
	component: Privacy,
};

const termsRoutes = {
	id: 'termsofservice',
	path: Urls.termsOfService,
	component: Terms,
};

const dataFlowRoutes = {
	id: 'dataFlow',
	path: Urls.dataflow,
	icon: <Link />,
	admin: true,
	children: [
		{
			path: Urls.links,
			name: 'links',
			component: Links,
			admin: true,
		},
		{
			path: Urls.routes,
			name: 'routes',
			component: RoutesData,
			admin: true,
		},
		{
			path: Urls.streams,
			name: 'streams',
			component: Streams,
			admin: true,
		},
	],
};

const linkAddRoute = {
	id: 'linkAdd',
	path: Urls.linkAdd,
	component: LinkAdd,
	admin: true,
};

const routeAddRoute = {
	id: 'routeAdd',
	path: Urls.routeAdd,
	component: RouteDataAdd,
	admin: true,
};

const routeRoute = {
	id: 'route',
	path: Urls.route,
	component: RouteData,
	admin: true,
};

const streamAddRoute = {
	id: 'streamAdd',
	path: Urls.streamAdd,
	component: StreamAdd,
	admin: true,
};

const streamDetailRoute = {
	id: 'streamSingle',
	path: Urls.streamSingle,
	component: Stream,
	admin: true,
};

const authRoutes = {
	id: 'Auth',
	path: Urls.auth,
	children: [
		{
			path: Urls.login,
			name: 'Log in',
			component: Login,
		},
		{
			path: Urls.register,
			name: 'Register',
			component: SignUp,
		},
		{
			path: Urls.resetPassword,
			name: 'Reset Password',
			component: ResetPassword,
		},
		{
			path: Urls.notFound,
			name: '404 Page',
			component: Page404,
		},
		{
			path: Urls.serverError,
			name: '500 Page',
			component: Page500,
		},
	],
};

export const dashboard = [
	dashboardsRoutes,
	devicesRoutes,
	profileRoutes,
	customerRoutes,
	adminRoutes,
	deviceDetailRoute,
	deviceIngestionAddRoute,
	deviceIngestionDetailRoute,
	privacyRoutes,
	termsRoutes,
	userProfileRoutes,
	profileAddRoute,
	profileApplicationAddRoute,
	profileDetailRoute,
	profileApplicationDetailRoute,
	profileSwVersionAddRoute,
	profileSwVersionDetailRoute,
	customerAddRoute,
	customerDetailRoute,
	userAddRoute,
	userDetailRoute,
	profileScriptsAddRoute,
	profileScriptDetailRoute,
	profileWebAppAddRoute,
	profileWebAppDetailRoute,
	profileConfigurationFileAddRoute,
	profileConfigurationFileDetailRoute,
	maintenanceAddRoute,
	maintenanceDetailRoute,
	deviceClassesAddRoute,
	deviceClassesDetailRoute,
	dataFlowRoutes,
	linkAddRoute,
	routeAddRoute,
	routeRoute,
	streamAddRoute,
	customerGroupAddRoute,
	customerGroupDetailRoute,
	streamDetailRoute,
];

export const auth = [authRoutes];

// Routes shown in menu
export default [
	dashboardsRoutes,
	devicesRoutes,
	profileRoutes,
	customerRoutes,
	dataFlowRoutes,
	adminRoutes,
];

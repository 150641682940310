import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetMaintenances' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetMaintenance', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(maintenance) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddMaintenance',
				subject: maintenance.subject,
				message: maintenance.message,
				expiration: maintenance.expiration,
				flags: maintenance.flags,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteMaintenance',
				id: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default { getAll, getById, add, del };

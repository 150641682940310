import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getByDevice(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetAction', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteAction', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default {
	getByDevice,
	del,
};

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetApplications' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetApplication', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getApplicationLibrariesByAppId(appId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetApplicationLibraryByAppId', application: appId }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(app) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddApplication',
				name: app.name,
				version: app.version,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function addLibConnection(appId, libId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddApplicationLibrary',
				application: appId,
				library: libId,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteApplication', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delLibConnection(appId) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteApplicationLibrary', application: appId }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function delLibConnectionById(appId, id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({ Command: 'DeleteApplicationLibraryById', id: id, application: appId })
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(application) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateApplication',
				id: application.id,
				name: application.name,
				version: application.version,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default {
	getAll,
	getById,
	add,
	addLibConnection,
	getApplicationLibrariesByAppId,
	del,
	delLibConnection,
	update,
	delLibConnectionById,
};

const Urls = {
	dashboard: '/',
	auth: '/auth',
	login: '/login',
	register: '/register',
	resetPassword: '/reset-password',
	notFound: '/404',
	serverError: '/500',
	userProfile: '/user',
	devices: '/devices',
	devicesAll: '/devices/all',
	devicesIngestion: '/devices/ingestion',
	devicesIngestionAdd: '/devices/ingestion/add',
	devicesIngestionSingle: '/devices/ingestion/:id',
	devicesSingle: '/devices/:id',
	devicesClasses: '/devices/classes',
	devicesClassAdd: '/devices/classes/add',
	devicesClassSingle: '/devices/classes/:id',
	profile: '/profile',
	profileAll: '/profile/all',
	profileAdd: '/profile/add',
	profileSingle: '/profile/:id',
	profileApplications: '/profile/applications',
	profileApplicationAdd: '/profile/applications/add',
	profileApplicationSingle: '/profile/application/:id',
	profileWebApp: '/profile/webapp',
	profileWebAppAdd: '/profile/webapp/add',
	profileWebAppSingle: '/profile/webapp/:id',
	profileSwVersion: '/profile/swversion',
	profileSwVersionAdd: '/profile/swversion/add',
	profileSwVersionSingle: '/profile/swversion/:id',
	profileConfigurationFiles: '/profile/configurationfiles',
	profileConfigurationFilesAdd: '/profile/configurationfiles/add',
	profileConfigurationFilesSingle: '/profile/configurationfiles/:id',
	profileScripts: '/profile/scripts',
	profileScriptsAdd: '/profile/scripts/add',
	profileScriptsSingle: '/profile/scripts/:id',
	customers: '/customers',
	customersAll: '/customers/all',
	customerAdd: '/customers/add',
	customerSingle: '/customers/:id',
	customerGroups: '/customers/groups',
	customerGroupAdd: '/customers/groups/add',
	customerGroupSingle: '/customers/groups/:id',
	administration: '/administration',
	administrationAll: '/administration/users',
	administrationServices: '/administration/services',
	administrationConsole: '/administration/console',
	administrationMaintenance: '/administration/maintenances',
	administrationMaintenanceAdd: '/administration/maintenances/add',
	administrationMaintenanceSingle: '/administration/maintenances/:id',
	administrationUsersAdd: '/administration/users/add',
	administrationUsersSingle: '/administration/users/:id',
	dataflow: '/dataflow',
	links: '/dataflow/links',
	linkAdd: '/dataflow/links/add',
	routes: '/dataflow/routes',
	routeAdd: '/dataflow/routes/add',
	route: '/dataflow/routes/:id',
	streams: '/dataflow/streams',
	streamAdd: '/dataflow/streams/add',
	streamSingle: '/dataflow/streams/:id',
	privacy: '/privacy',
	termsOfService: '/termsofservice',
};

export default Urls;

import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

import { saveUser, isUserAdmin } from 'utils';
import EndPoints from 'api/endpoints';
import Api from 'api';
import store from 'redux/store';

const CircularProgressContainer = styled.div`
	text-align: center;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	right: 0;
	margin-right: auto;
	margin-left: auto;
`;

const PrivateRoute = ({ Component, Layout, admin, ...rest }) => {
	const [checkUser, handleCheckUser] = useState(false);
	const [logged, handleLogged] = useState(false);

	const getStatus = () => {
		Api.login.getStatus().then((res) => {
			if (res) {
				const { LoggedIn, User } = res;
				if (LoggedIn && User) {
					Api.users.getByUsername(User.UserName).then((res) => {
						const { result, Output } = res;

						if (result.code === 0) {
							const savedUser = store.getState().userReducer.user;

							handleLogged(LoggedIn);
							handleCheckUser(true);

							if (LoggedIn && Output && Output.length > 0 && !savedUser) {
								saveUser(Output[0]);
							}
						}
					});
				} else {
					handleLogged(false);
					handleCheckUser(true);
				}
			}
		});
	};

	useEffect(() => {
		getStatus();
	}, [rest.path]);

	if (!isUserAdmin() && isUserAdmin() !== null && admin && checkUser) {
		return <Redirect to={EndPoints.home} />;
	}

	if (checkUser && !logged) {
		return <Redirect to={EndPoints.login} />;
	}

	return checkUser ? (
		<Route
			{...rest}
			render={(props) => (
				<Layout history={props.history}>
					<Component {...props} />
				</Layout>
			)}
		/>
	) : (
		<CircularProgressContainer>
			<CircularProgress />
		</CircularProgressContainer>
	);
};

export default PrivateRoute;

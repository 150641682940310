import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { rgba } from 'polished';
import store from 'redux/store/index';

import { NavLink as RouterNavLink, withRouter } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import '../../vendor/perfect-scrollbar.css';

import {
	Chip,
	Collapse,
	Drawer as MuiDrawer,
	List as MuiList,
	ListItem,
	ListItemText,
	Grid,
	Typography,
	Avatar,
} from '@material-ui/core';

import { ExpandLess, ExpandMore } from '@material-ui/icons';

import routes from '../../routes/index';
import { isUserAdmin } from 'utils';

const NavLink = React.forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

const Drawer = styled(MuiDrawer)`
	border-right: 0;

	> div {
		border-right: 0;
	}
`;

const Scrollbar = styled(PerfectScrollbar)`
	background-color: ${(props) => props.theme.sidebar.background};
	border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
	background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
	padding: 0;
`;

const Brand = styled(ListItem)`
	font-size: ${(props) => props.theme.typography.h5.fontSize};
	font-weight: ${(props) => props.theme.typography.fontWeightMedium};
	color: ${(props) => props.theme.sidebar.header.color};
	background-color: ${(props) => props.theme.sidebar.header.background};
	font-family: ${(props) => props.theme.typography.fontFamily};
	min-height: 56px;
	padding-left: ${(props) => props.theme.spacing(6)}px;
	padding-right: ${(props) => props.theme.spacing(6)}px;

	${(props) => props.theme.breakpoints.up('sm')} {
		min-height: 64px;
	}
`;

const Category = styled(ListItem)`
	padding-top: ${(props) => props.theme.spacing(3)}px;
	padding-bottom: ${(props) => props.theme.spacing(3)}px;
	padding-left: ${(props) => props.theme.spacing(6)}px;
	padding-right: ${(props) => props.theme.spacing(5)}px;
	font-weight: ${(props) => props.theme.typography.fontWeightRegular};

	svg {
		color: ${(props) => props.theme.sidebar.color};
		font-size: 20px;
		width: 20px;
		height: 20px;
		opacity: 0.5;
	}

	&:hover {
		background: rgba(0, 0, 0, 0.08);
	}

	&.${(props) => props.activeClassName} {
		background-color: ${(props) => props.theme.sidebar.activeMenu};

		span {
			color: ${(props) => props.theme.sidebar.color};
		}
	}
`;

const CategoryText = styled(ListItemText)`
	margin: 0;
	span {
		color: ${(props) => props.theme.sidebar.color};
		font-size: ${(props) => props.theme.typography.body1.fontSize}px;
		font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
		padding: 0 ${(props) => props.theme.spacing(4)}px;
	}
`;

const CategoryIconLess = styled(ExpandLess)`
	color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
	color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
	padding-left: ${(props) => props.theme.spacing(14)}px;
	padding-top: ${(props) => props.theme.spacing(2)}px;
	padding-bottom: ${(props) => props.theme.spacing(2)}px;

	span {
		color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
	}

	&:hover span {
		color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
	}

	&.${(props) => props.activeClassName} {
		background-color: ${(props) => props.theme.sidebar.activeMenu};

		span {
			color: ${(props) => props.theme.sidebar.color};
		}
	}
`;

const LinkText = styled(ListItemText)`
	color: ${(props) => props.theme.sidebar.color};
	span {
		font-size: ${(props) => props.theme.typography.body1.fontSize}px;
	}
	margin-top: 0;
	margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
	font-size: 11px;
	font-weight: ${(props) => props.theme.typography.fontWeightBold};
	height: 20px;
	position: absolute;
	right: 12px;
	top: 8px;
	background: ${(props) => props.theme.sidebar.badge.background};

	span.MuiChip-label,
	span.MuiChip-label:hover {
		cursor: pointer;
		color: ${(props) => props.theme.sidebar.badge.color};
		padding-left: ${(props) => props.theme.spacing(2)}px;
		padding-right: ${(props) => props.theme.spacing(2)}px;
	}
`;

const CategoryBadge = styled(LinkBadge)`
	top: 12px;
`;

const SidebarFooter = styled.div`
	background-color: ${(props) => props.theme.sidebar.footer.background} !important;
	padding: ${(props) => props.theme.spacing(2.75)}px ${(props) => props.theme.spacing(4)}px;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
	color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
	color: ${(props) => props.theme.sidebar.footer.color};
	font-size: 0.725rem;
	display: block;
	padding: 1px;
`;

function SidebarCategory({ name, icon, classes, isOpen, isCollapsable, badge, ...rest }) {
	return (
		<Category {...rest}>
			{icon}
			<CategoryText>{name}</CategoryText>
			{isCollapsable ? isOpen ? <CategoryIconMore /> : <CategoryIconLess /> : null}
			{badge ? <CategoryBadge label={badge} /> : ''}
		</Category>
	);
}

function SidebarLink({ name, to, badge }) {
	return (
		<Link button dense component={NavLink} exact to={to} activeClassName="active">
			<LinkText>{name}</LinkText>
			{badge ? <LinkBadge label={badge} /> : ''}
		</Link>
	);
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	toggle = (index) => {
		// Collapse all elements
		Object.keys(this.state).forEach(
			(item) =>
				this.state[index] ||
				this.setState(() => ({
					[item]: false,
				}))
		);

		// Toggle selected element
		this.setState((state) => ({
			[index]: !state[index],
		}));
	};

	UNSAFE_componentWillMount() {
		/* Open collapse element that matches current url */
		const pathName = this.props.location.pathname;

		routes.forEach((route, index) => {
			const isActive = pathName.indexOf(route.path) === 0;
			const isOpen = route.open;
			const isHome = route.containsHome && pathName === '/' ? true : false;

			this.setState(() => ({
				[index]: isActive || isOpen || isHome,
			}));
		});
	}

	render() {
		const { classes, staticContext, t, tReady, ...other } = this.props;
		const { user } = store.getState().userReducer;

		const avatarLetters =
			user && user.name !== '' && user.name.split(' ').length > 1
				? `${user.name.split(' ')[0].substr(0, 1)}${user.name.split(' ')[1].substr(0, 1)}`
				: `${user && user.name !== '' ? user.name.substr(0, 2) : ''} `;

		return (
			<Drawer variant="permanent" {...other}>
				<Brand>
					<img
						src={`/static/img/logo.png`}
						alt="ACS Suite"
						style={{ maxWidth: '100px' }}
					/>
				</Brand>
				<Scrollbar>
					<List disablePadding>
						<Items>
							{routes.map((category, index) => {
								const isVisible =
									(category.admin && isUserAdmin()) || !category.admin;
								return isVisible ? (
									<React.Fragment key={index}>
										{category.children ? (
											<React.Fragment key={index}>
												<SidebarCategory
													isOpen={!this.state[index]}
													isCollapsable={true}
													name={t(`menu.${category.id}`)}
													icon={category.icon}
													button={true}
													onClick={() => this.toggle(index)}
												/>

												<Collapse
													in={this.state[index]}
													timeout="auto"
													unmountOnExit
												>
													{category.children.map((route, index) => {
														const isChildVisible =
															(route.admin && isUserAdmin()) ||
															!route.admin;
														return isChildVisible ? (
															<SidebarLink
																key={index}
																name={t(`menu.${route.name}`)}
																to={route.path}
																icon={route.icon}
																badge={route.badge}
															/>
														) : null;
													})}
												</Collapse>
											</React.Fragment>
										) : (
											<SidebarCategory
												isCollapsable={false}
												name={t(`menu.${category.id}`)}
												to={category.path}
												activeClassName="active"
												component={NavLink}
												icon={category.icon}
												exact
												badge={category.badge}
											/>
										)}
									</React.Fragment>
								) : null;
							})}
						</Items>
					</List>
				</Scrollbar>
				{user && (
					<SidebarFooter>
						<Grid container spacing={2} alignItems="center">
							<Grid item>
								<Avatar>{avatarLetters.toUpperCase()}</Avatar>
							</Grid>
							<Grid item>
								<SidebarFooterText variant="body2">{user.name}</SidebarFooterText>
								<SidebarFooterSubText variant="caption">
									{isUserAdmin() ? 'Admin' : 'User'}
								</SidebarFooterSubText>
							</Grid>
						</Grid>
					</SidebarFooter>
				)}
			</Drawer>
		);
	}
}

export default withRouter(withTranslation()(Sidebar));

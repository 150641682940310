import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function get() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetService' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(services) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateService',
				serverPort: services.serverPort,
				acsUsername: services.acsUsername,
				acsPassword: services.acsPassword,
				fsUsername: services.fsUsername,
				fsPassword: services.fsPassword,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default { get, update };

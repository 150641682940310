import React from 'react';
import { connect } from 'react-redux';

import Helmet from 'react-helmet';

import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider } from 'styled-components';

import theme from './theme';
import Routes from './routes/Routes';

import './i18n';

function App() {
	return (
		<React.Fragment>
			<Helmet titleTemplate="%s | AG Suite" defaultTitle="AG Suite" />
			<StylesProvider injectFirst>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<MuiThemeProvider theme={theme}>
						<ThemeProvider theme={theme}>
							<Routes />
						</ThemeProvider>
					</MuiThemeProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</React.Fragment>
	);
}

export default connect((store) => ({ theme: store.themeReducer, user: store.userReducer }))(App);

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetConfigurations' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetConfiguration', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function add(configuration) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'AddConfiguration',
				version: configuration.version,
				description: configuration.description,
				fileName: configuration.fileName,
				hardware: configuration.hardware,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'DeleteConfiguration',
				id: id,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

function update(configuration) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(
			JSON.stringify({
				Command: 'UpdateConfiguration',
				id: configuration.id,
				version: configuration.version,
				description: configuration.description,
				fileName: configuration.fileName,
				hardware: configuration.hardware,
			})
		)
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default { getAll, getById, add, del, update };

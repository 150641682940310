import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { OutlinedInput, Select, MenuItem, FormControl, Button } from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import Config from '../../utils/config';

const SearchFieldsContainer = styled.div`
	display: flex;
	align-items: center;
`;

const SingleInputContainer = styled.div`
	display: flex;
	align-items: center;
	margin-right: 10px;
`;

const Search = styled.div`
	border-radius: 2px;
	background-color: ${(props) => props.theme.header.background};
	display: none;
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
`;

const SearchIconWrapper = styled.div`
	width: 50px;
	height: 100%;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 22px;
		height: 22px;
	}
`;

const Input = styled(OutlinedInput)`
	color: inherit;
	width: 100%;

	> input {
		color: ${(props) => props.theme.header.search.color};
	}
`;

const clearLocalStorage = () => {
	Object.entries(localStorage).map((el) => {
		if (el[0].indexOf('search-') !== -1) {
			delete localStorage[el[0]];
		}
		return true;
	});
};

clearLocalStorage();

const SearchForm = ({ pathname, t, onSearch }) => {
	const fields = Config.searches[pathname] ? Config.searches[pathname].fields : [];
	const defaultValue = {};

	fields.map((el) => {
		defaultValue[el.name] = '';
		return true;
	});

	const [value, handleValue] = useState(defaultValue);

	const sanitizeStrings = (str) => str.toLowerCase();

	const onChange = (type, val) => {
		const clonedValue = JSON.parse(JSON.stringify(value));
		clonedValue[type] = sanitizeStrings(val);

		handleValue(clonedValue);
		onSearch(clonedValue);

		localStorage[`search-${pathname}`] = JSON.stringify(clonedValue);
	};

	const renderFields = () => {
		return fields.map((f) => {
			const { tagType, type, name, options } = f;
			if (tagType === 'input') {
				return (
					<SingleInputContainer key={name}>
						<Input
							placeholder={t(`search.${name}`)}
							variant="outlined"
							type={type}
							value={value[name] || ''}
							onChange={(e) => {
								onChange(name, e.target.value);
							}}
						/>
					</SingleInputContainer>
				);
			}

			if (tagType === 'select') {
				return (
					<SingleInputContainer key={name}>
						<FormControl variant="outlined">
							<Select
								labelId={name}
								id={name}
								value={value[name] || `select_${name}`}
								onChange={(e) => {
									onChange(
										name,
										e.target.value === 'clear' ? '' : e.target.value
									);
								}}
							>
								<MenuItem disabled value={`select_${name}`} selected>
									{t(`search.${name}`)}
								</MenuItem>
								<MenuItem value="clear">
									{name === 'adminOnly' ? 'Clear' : 'ALL'}
								</MenuItem>
								{options.map((o) => {
									return (
										<MenuItem key={o} value={sanitizeStrings(o)}>
											{o}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</SingleInputContainer>
				);
			}

			return true;
		});
	};

	const cleanSearchValue = () => {
		handleValue(defaultValue);
		onSearch(defaultValue);
		delete localStorage[`search-${pathname}`];
	};

	useEffect(() => {
		if (fields.length > 0) {
			if (localStorage[`search-${pathname}`]) {
				const searchObj = JSON.parse(localStorage[`search-${pathname}`]);
				handleValue(searchObj);
				onSearch(searchObj);
			} else {
				cleanSearchValue();
			}
		}
	}, [pathname]); // eslint-disable-line

	return fields.length > 0 ? (
		<div>
			<Search>
				<SearchIconWrapper>
					<SearchIcon />
				</SearchIconWrapper>
				<SearchFieldsContainer>
					{renderFields()}
					<Button
						onClick={() => {
							cleanSearchValue();
						}}
						color="primary"
					>
						{t('message.clear')}
					</Button>
				</SearchFieldsContainer>
			</Search>
		</div>
	) : null;
};

SearchForm.propTypes = {
	pathname: PropTypes.string,
};

export default withTheme(withTranslation()(SearchForm));

import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function getAll() {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetDevices' }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getLast(max) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetLastDevices', max: max }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getById(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetDevice', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getByProfile(profile) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetDevicesByProfile', profile: profile }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getByCustomerId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetDevicesByCustomerId', Customer: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function del(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'DeleteDevice', id: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function updateIngestion(id, ingestion) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'UpdateDeviceIngestion', id: id, eventhub: ingestion }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function updateProfile(id, profile) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'UpdateDeviceProfile', id: id, profile: profile }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function removeIngestion(ingestion) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'RemoveDeviceIngestion', eventhub: ingestion }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

function getCustomersDeviceByDeviceId(id) {
	return HttpClient.options({})
		.url(`${EndPoints.capiJson}`)
		.body(JSON.stringify({ Command: 'GetCustomersDeviceByDeviceId', device: id }))
		.post()
		.json((json) => json)
		.then((response) => response);
}

export default {
	getAll,
	getLast,
	getById,
	getByProfile,
	getByCustomerId,
	del,
	updateIngestion,
	updateProfile,
	removeIngestion,
	getCustomersDeviceByDeviceId,
};

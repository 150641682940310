import EndPoints from '../endpoints';
import HttpClient from '../httpClient/httpClient';

function post() {
	return HttpClient.options({})
		.headers({ 'Content-Type': 'application/json' })
		.url(`${EndPoints.login}`)
		.body(JSON.stringify({ Command: 'Logout' }))
		.post()
		.json(json => json)
		.then(response => response);
}

export default { post };

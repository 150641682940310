import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
// import { NavLink as RouterNavLink } from 'react-router-dom';

import pjson from '../../../package.json';

import { Grid, Hidden, List, ListItemText, ListItem as MuiListItem } from '@material-ui/core';

// import Urls from 'routes/urls';

// const NavLink = React.forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

const Wrapper = styled.div`
	padding: ${(props) => props.theme.spacing(1) / 4}px ${(props) => props.theme.spacing(4)}px;
	background: ${(props) => props.theme.palette.common.white};
	position: relative;
`;

const ListItem = styled(MuiListItem)`
	display: inline-block;
	width: auto;
	padding-left: ${(props) => props.theme.spacing(2)}px;
	padding-right: ${(props) => props.theme.spacing(2)}px;

	&,
	&:hover,
	&:active {
		color: #000;
	}
`;

function Footer({ t }) {
	return (
		<Wrapper>
			<Grid container spacing={0}>
				<Hidden smDown>
					<Grid container item xs={12} md={6}>
						{/* <List>
							<ListItem component={NavLink} to={Urls.privacy}>
								<ListItemText primary={`${t('footer.privacy')}`} />
							</ListItem>
							<ListItem component={NavLink} to={Urls.termsOfService}>
								<ListItemText primary={`${t('footer.termsOfService')}`} />
							</ListItem>
						</List> */}
					</Grid>
				</Hidden>
				<Grid container item xs={12} md={6} justify="flex-end">
					<List>
						<ListItem>
							<ListItemText primary={`© ${new Date().getFullYear()} Gemino`} />
						</ListItem>
						<ListItem>
							<ListItemText primary={`v ${pjson.version}`} />
						</ListItem>
					</List>
				</Grid>
			</Grid>
		</Wrapper>
	);
}

export default withTranslation()(Footer);
